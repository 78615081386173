import Icon from '@ant-design/icons';
import React from 'react';

const LogoSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1156 1156">
    <path
      d="M576.5,61.7c-0.8-0.1-1.6-0.1-2.4-0.1c-11.6,0.6-23.7,2.8-32.3,10.5c-6.6,5.9-11.4,13.5-16.2,20.9
		c-20.9,32.3-46.1,61.9-74.6,87.8c-28.5,25.8-63.2,53.4-63.9,91.9c-0.7,40.2,37.1,72.6,39,112.7c1.2,26.6-13.6,51.6-14.5,78.1
		c-1.1,34.4,21.3,68.1,13,101.5c-3.3,13.2-11.2,24.8-19.8,35.4c-26.1,32.2-60,58-97.9,74.6c-24,10.5-50.8,17.4-76.4,12.1
		c-25.7-5.3-49.5-25.4-52.7-51.4c-4.2-34.6,27.1-63,57.9-79.4s66.4-29.5,84.3-59.5c18.2-30.6,11.1-72.4-12.4-99.2
		c-23.5-26.7-60.6-39.2-96.2-38.1c-27.8,0.9-61.5,17.9-58.5,45.5c2.2,20.4,29.2,33.3,46.5,22.2c14.4-9.2,19.3-29.3,34.1-37.8
		c21.6-12.3,50.1,10.9,50.4,35.7s-18.5,46-39.5,59.4s-45,21.6-65.2,36c-51.9,37-68.5,113.7-40.8,171.1
		c17.2,35.7,49.6,62.6,86.4,76.4c53.4,20.1,161.7-13.5,168,71.2c4.4,58.7-62.8,77-84.4,123.1c-15.3,32.8-3.6,74.3,23.1,98.7
		c26.7,24.4,65.5,32.8,101.3,27.8c19.6-2.8,41.3-11.8,46.8-30.9c6.8-23.8-25.1-47.7-46-34.4c-14.7,9.3-19.8,29.7-35,38.1
		c-21.1,11.6-48.8-10-50-34.1c-2-39.8,38-58.7,65.4-78.6c37.8-27.5,70.6-60.8,98.7-98.1c15.9-21.1,36.2-39.1,64.1-38.5
		c27.9-0.6,48.2,17.5,64.1,38.5c28.1,37.3,60.9,70.6,98.7,98.1c27.4,19.9,67.5,38.8,65.4,78.6c-1.2,24.1-28.9,45.7-50,34.1
		c-15.2-8.4-20.3-28.8-35-38.1c-20.9-13.3-52.9,10.6-46,34.4c5.4,19,27.2,28.1,46.8,30.9c35.8,5,74.6-3.4,101.3-27.8
		s38.4-66,23.1-98.7c-21.6-46.1-88.8-64.4-84.4-123.1c6.3-84.7,114.6-51.1,168-71.2c36.8-13.9,69.1-40.7,86.4-76.4
		c27.7-57.4,11.1-134.1-40.8-171.1c-20.2-14.4-44.2-22.6-65.2-36s-39.7-34.5-39.5-59.4c0.3-24.8,28.8-48,50.4-35.7
		c14.9,8.5,19.7,28.6,34.1,37.8c17.3,11.1,44.2-1.8,46.5-22.2c3-27.7-30.7-44.7-58.5-45.5c-35.6-1.1-72.7,11.3-96.2,38.1
		c-23.5,26.7-30.7,68.6-12.4,99.2c17.9,30,53.5,43.1,84.3,59.5s62.1,44.8,57.9,79.4c-3.2,26-27,46.1-52.7,51.4
		c-25.7,5.3-52.4-1.6-76.4-12.1c-37.9-16.6-71.8-42.4-97.9-74.6c-8.6-10.6-16.5-22.2-19.8-35.4c-8.3-33.4,14.1-67.1,13-101.5
		c-0.8-26.6-15.7-51.6-14.5-78.1c1.9-40.1,39.7-72.5,39-112.7c-0.7-38.5-35.4-66-63.9-91.9c-28.5-25.9-53.7-55.5-74.6-87.8
		c-4.8-7.4-9.6-15-16.2-20.9c-8.6-7.7-20.7-9.9-32.3-10.5C578.1,61.6,577.3,61.7,576.5,61.7z"
    />
  </svg>
);

type Props = {
  size: string | number;
};

export const Logo: React.FC<Props> = (props) => {
  return <Icon component={LogoSvg} style={{ fontSize: props.size }} />;
};
